<template>
  <v-layout class="bonus card-layout gap" key="benefitsContainerKey">
    <div class="title column">
      <div class="title-text" style="color: rgba(var(--theme-primaria))">
        <h1>Conquiste vantagens <br /><span style="color: rgba(var(--theme-primaria))">especiais</span></h1>
        <div class="dot" 
            style="background: rgba(var(--theme-primaria));"></div>
      </div>
      <p class="default-text" >
        Você pode ajudar ainda mais e ainda leva vantagens exclusivas:
      </p>
    </div>
    <div v-for="(b, index) in bonus" :key="`${b.type}_${index}`" class="box">
      <div class="box-section">
        <div class="cart-item p-0">
          <div
            class="bonus-image"
            :style="'--background-image: url('+b.img+'); background-size: contain;'"
          ></div>
          <div class="cart-item-details">
            <div class="item-title">
              <div class="bonus-name">
                <span>
                  {{ b.type }}
                  <i class="fal fa-plus-circle extra primary"
                  style="color: rgba(var(--theme-primaria))"
                  ></i>
                </span>
                <p style="color: rgba(var(--theme-primaria))">
                  {{ b.title }}
                </p>
              </div>
            </div>
            <div class="display-flex flex-column">
              <div class="bonus-value">
                <caption>
                  Valor adicional
                </caption>
                <p>{{ b.additional }}</p>
              </div>

              <div class="bonus-value highlight">
                <caption>
                  Valor total
                </caption>
                <p style="color: rgba(var(--theme-primaria))">
                  {{ b.total }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="benefits" v-if="b.benefits">
        <div
          v-for="(benefit, b_index) in b.benefits"
          :key="`${b.type}_${index}_benefit_${b_index}`"
          class="benefits-item"
        >
          <i class="fal fa-check primary"
          style="color: rgba(var(--theme-primaria))"
          ></i>
          <p :class="{ 'b-top': b_index > 0 }">{{ benefit }}</p>
        </div>
      </div>

      <!-- <div class="bonus-more">
        <v-btn class="btn btn-default">
          Veja todos os benefícios
          <i class="fal fa-angle-right"></i>
        </v-btn>
      </div> -->

      <div class="bonus-actions">
        <v-spacer></v-spacer>
        <v-btn class="btn btn-primary"
          @click="select(b.product)"
          :style="!disableButton ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
        >
          Eu quero
          <i class="fal fa-angle-right"></i>
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script>
// Services
import CampaignService from "@/scripts/services/campaign.service";

// VUEX
import { mapGetters, mapActions } from "vuex";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

export default {
  props: {
    paymentMethods: Array,
  },
  data() {
    return {
      campaignService: new CampaignService(),
      formatterHelper: new FormatterHelper(),
      bonus: [],
      campaignId: null,
      products: null,
      appreciation: null,
      nextAppreciationList: null,
      nextAppreciationsLimit: 2,
      benefitsContainerKey: 0
    };
  },
  created() {
    this.refreshCampaignGifts()
  },
  async mounted() {
    this.goToTop();
    this.products = this.isInMaintenanceValue.isInMaintenanceValue ? this.isInMaintenanceValue.products[0] : null
    this.appreciation = await this.getActualAppreciation()
    this.nextAppreciationList = await this.getNextAppreciations()
    this.getMaintanenceValueProduct()
    this.getBonus()
    if (this.campaignGiftsConfig){
      this.getGiftConfigBonus()
    }
    await this.orderBonus()
  },
  watch: {
    campaignGiftsConfig(val) {
      console.log(val)
    }
  },
  computed: {
    ...mapGetters([
      'isInMaintenanceValue',
      'checkoutValue',
      'campaignGifts',
      'nextAppreciationList',
      'appreciationList',
      'campaignGiftsConfig',
      'campaignId'
    ]),
  },
  methods: {
    ...mapActions([
      'refreshCampaignGifts',
      'setSelectedGift',
    ]),
    goToTop() {
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    },
    select(item) {
      this.setSelectedGift(item);
      this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "Brinde selecionado!");
      this.$emit('next', true)
    },
    getMaintanenceValueProduct() {
      if (this.products) {
          this.products.initialValue =this.isInMaintenanceValue.productValueForAppreciation

        const bonus = {
          type: this.appreciation.name && (this.appreciation.name).trim() !== '' ? this.appreciation.name : `De ${this.formatterHelper.formatMoneyWithCents(this.appreciation.firstValue)} à ${this.formatterHelper.formatMoneyWithCents(this.appreciation.lastValue)}`,
          title: this.products.name,
          additional: this.formatterHelper.formatMoneyWithCents(this.isInMaintenanceValue.productValueForAppreciation),
          total: this.formatterHelper.formatMoneyWithCents(this.checkoutValue + this.isInMaintenanceValue.productValueForAppreciation),
          img: this.products.file.path,
          product: this.products,
          benefits: []
        }

        const benefitsDescriptions = this.splitBenefitsWithDots(this.appreciation.campaignAppreciationItems)

        benefitsDescriptions.forEach((item) => {
          bonus.benefits.push((item).replaceAll('<span>','').replaceAll('</span>','').replaceAll('<li>','').replaceAll('</li>',''))
        })

        if (this.bonus.indexOf(bonus) === -1)  this.bonus.push(bonus)
      }
    },
    getGiftConfigBonus() {
      const percentageValue = ((this.checkoutValue * this.campaignGiftsConfig.minPercentageMaintenanceTax) / 100)
      this.campaignGifts.forEach((gift) => {       
        if ((parseFloat(gift.initialValue) >= percentageValue) && (parseFloat(gift.finalValue) <= this.appreciation.lastValue) && (gift.active === true)) {
          
          const bonus = {
          type: this.appreciation.name && (this.appreciation.name).trim() !== '' ? this.appreciation.name : `De ${this.formatterHelper.formatMoneyWithCents(this.appreciation.firstValue)} à ${this.formatterHelper.formatMoneyWithCents(this.appreciation.lastValue)}`,
          title: gift.name,
          additional: this.formatterHelper.formatMoneyWithCents(gift.initialValue),
          total: this.formatterHelper.formatMoneyWithCents(this.checkoutValue + parseFloat(gift.initialValue)),
          img: gift.file.path,
          product: gift,
          benefits: []
        }

        const benefitsDescriptions = this.splitBenefitsWithDots(this.appreciation.campaignAppreciationItems)

        benefitsDescriptions.forEach((item) => {
          bonus.benefits.push((item).replaceAll('<span>','').replaceAll('</span>','').replaceAll('<li>','').replaceAll('</li>',''))
        })

        if (this.bonus.findIndex((b) => b.title === gift.name) === -1) this.bonus.push(bonus)
        }
      })
    },
    getBonus() {
      this.nextAppreciationList.forEach((appreciation) => {
        if(appreciation.product)
          appreciation.product.initialValue = appreciation.productValueForAppreciation

        if(appreciation.product) {
          const bonus = {
            type: appreciation.name && (appreciation.name).trim() !== '' ? appreciation.name : `De ${this.formatterHelper.formatMoneyWithCents(appreciation.firstValue)} à ${this.formatterHelper.formatMoneyWithCents(appreciation.lastValue)}`,
            title: appreciation.product.name,
            additional: this.formatterHelper.formatMoneyWithCents(appreciation.productValueForAppreciation),
            total: this.formatterHelper.formatMoneyWithCents(this.checkoutValue + appreciation.productValueForAppreciation),
            benefits: [],
            product: appreciation.product,
            img: appreciation.product.file.path
          }
          
          const benefitsDescriptions = this.splitBenefitsWithDots(appreciation.campaignAppreciationItems)

          benefitsDescriptions.forEach((item) => {
            bonus.benefits.push((item).replaceAll('<span>','').replaceAll('</span>','').replaceAll('<li>','').replaceAll('</li>',''))
          })

          if (this.bonus.indexOf(bonus) === -1) this.bonus.push(bonus)
        }
      })
      
    },
    async getActualAppreciation() {
      return new Promise((resolve) => {
        const result = this.appreciationList.find((appreciation) => {
          if (appreciation.lastValue !== 0)
            return (this.checkoutValue >= appreciation.firstValue) && (this.checkoutValue <= appreciation.lastValue)
          else if (appreciation.lastValue === 0)
            return (this.checkoutValue >= appreciation.firstValue)
          else
            return appreciation.id !== 0
        })
        resolve(result)
      })
    },
    async getNextAppreciations() {
      return new Promise((resolve) => {
        const result = this.appreciationList.filter((appreciation) => {
          const differenceBetween = (appreciation.firstValue - this.checkoutValue)
          appreciation.productValueForAppreciation = differenceBetween
          appreciation.product = this.campaignGifts.find((product) => {
            return (differenceBetween >= product.initialValue) && (differenceBetween <= product.finalValue) && (product.active === true)
          })
          if (this.appreciation)
            return ((this.appreciation.lastValue < appreciation.firstValue) && this.appreciation.id !== appreciation.id)
          else
            return (this.checkoutValue < appreciation.firstValue)
        })
        resolve(result.sort((a,b) => a.firstValue - b.firstValue))
      })
    },
    splitBenefitsWithDots(benefit) {
      const splitedArray = []
      benefit.forEach((item) => {
        const itemSplited = item.description.split('•')
        if (itemSplited.length > 1) {
          itemSplited.forEach((splited) => {
            splitedArray.push(splited)
          })
        }
        else {
          splitedArray.push(itemSplited[0])
        }
      })
      return splitedArray
    },
    async orderBonus() {
      new Promise((resolve) => {
        this.bonus = this.bonus.sort((a,b) => parseFloat(a.product.initialValue) - parseFloat(b.product.initialValue))
        resolve(this.bonus)
      })
    }
  },
};
</script>
