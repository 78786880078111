<template>
  <v-layout>
    <div class="v-window-head">
      <div class="title">
        <div class="title-text">
          <h2 
            v-if="campaignProductCall"
            style="text-transform: uppercase;"
            >
            {{ campaignProductCall }}
          </h2>
          <h2 
            v-else
            style="text-transform: uppercase;"
            >
            Agradecimento
          </h2>
          <div class="dot" 
            style="background: rgba(var(--theme-primaria));"></div>
        </div>
      </div>
      <div
        v-if="campaignDonationProduct && campaignDonationProduct.campaignProductQuestionList.length > 0"
        style="width:100%;"
      >
        <div
          :key="'acknowledgement'+campaignDonationProduct.id"
        >
          <div class="box">
            <div class="box-section">
              <div class="box-title flex-column" style="align-items: start;">
                <div class="d-flex flex-row align-center justify-center">
                  <h3 class="pr-1">{{ campaignDonationProduct.name }}</h3>
                  <i class="fas fa-info-circle primary" style="color: rgba(var(--theme-primaria))"></i>
                </div>
                <div>
                  <p v-if="campaignDonationProduct.suggestionValue">{{ campaignDonationProduct.suggestionValue }}</p>
                </div>
              </div><br>
              <div class="box-title align-left">
                <p>{{ campaignDonationProduct.description }}</p>
              </div><br>
              <div class="box-subtitle align-left">
                Valor da doação
                <p style="color: rgba(var(--theme-primaria));">{{ totalValue(checkoutValue) }}</p>
              </div>
            </div>
          </div>
          <div class="cart-item"
              :key="'product'+campaignDonationProduct.id"
            >
              <div
                class="item-image lg"
                :style="`--background-image: url(${campaignDonationProduct.file.path});`"
                style="background-size: cover;"
              ></div>
              <div class="cart-item-details">
                <div class="item-input" style="flex-direction: column;">
                  <!-- <div
                              class="custom-field-text text-lg text-highlight text-end"
                            >
                              <span>R$</span>
                              <input type="text" value="2.000,00" />
                            </div> -->
                  <div class="custom-field-select expand disabled"
                    v-for="question in campaignDonationProduct.campaignProductQuestionList"
                    :key="'question'+question.id"
                  >
                    <InputSelect
                      ref="donationProductSelectAnswerInput"
                      :items="question.campaignProductQuestionAlternatives"
                      valueAttribute="description"
                      textAttribute="description"
                      :key="'alternative'+question.id"
                      type="text"
                      :textLabel="question.question"
                      v-model="question.answer"
                      invalidInputMessage="Escolha uma opção"
                  />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div 
        style="width: 100%;"
      v-else>
        <div
          v-for="cartItem in cartItems"
          :key="'acknowledgement'+cartItem.id"
        >
          <div class="box">
            <div class="box-section"
              v-if="cartItem.donationProduct"
            >
              <div class="box-title">
                <h3>{{ cartItem.project.fullName }}</h3>
                <i class="fas fa-info-circle primary"></i>
              </div><br>
              <div class="box-title align-left">
                <p>{{ cartItem.donationProduct.description }}</p>
              </div><br>
              <div class="box-subtitle align-left">
                Valor da doação
                <p>{{ totalValue(checkoutValue) }}</p>
              </div>
            </div>

            

          </div>
          <div class="cart-item"
              v-if="cartItem.donationProduct !== null"
              :key="cartItem.donationProduct.id ? 'product'+cartItem.donationProduct.id : 'product1'"
            >
            <div
              class="item-image lg"
              :style="`--background-image: url(${cartItem.donationProduct.file.path});`"
              style="background-size: cover;"
            ></div>
          <div class="cart-item-details">
            <div class="item-input" style="flex-direction: column;">
              <!-- <div
                          class="custom-field-text text-lg text-highlight text-end"
                        >
                          <span>R$</span>
                          <input type="text" value="2.000,00" />
                        </div> -->

              <div class="custom-field-select expand disabled"
                v-for="question in cartItem.donationProduct.donationProductQuestionList"
                :key="'question'+question.id"
              >
                <InputSelect
                  ref="donationProductSelectAnswerInput"
                  :items="question.donationProductQuestionAlternatives"
                  valueAttribute="description"
                  textAttribute="description"
                  :key="'alternative'+question.id"
                  type="text"
                  :textLabel="question.question"
                  v-model="question.answer"
                  invalidInputMessage="Escolha uma opção"
              />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      
      
      
    </div>
    
  </v-layout>
</template>

<script>
// Services
import CampaignService from "@/scripts/services/campaign.service.js";

// VUEX
import { mapGetters, mapActions } from "vuex";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

export default {
  data() {
    return {
      campaignService: new CampaignService(),
      formatterHelper: new FormatterHelper(),
      products: null
    };
  },
  computed: {
    ...mapGetters([
      "cartItems",
      "checkoutValue",
      "campaignDonationProduct",
      "campaignProductCall"
    ]),
  },
  mounted() {
    this.getCampaignProductCall();
  },
  methods: {
    ...mapActions([
      "getCampaignProductCall"
    ]),
    totalValue(value) {
      return this.formatterHelper.formatMoneyWithCents(value);
    }
  },
};
</script>
