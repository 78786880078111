<template>
  <v-expansion-panel class="payment-method" v-model="panelOpen">
    <v-expansion-panel-content>
      <template v-slot:actions>
        <v-switch :value="panelOpen === 0" readonly color="rgba(var(--theme-primaria))"></v-switch>
      </template>
      <template v-slot:header>
        <img width="24px" :src="`/images/payment-methods/payroll.svg`" :alt="`Payroll icon`" />
        <p>{{ params.title }}</p>
        <span style="color: rgba(var(--theme-primaria))" v-if="recurrency == 1">Veja as opções de parcelamento</span>
        <span style="color: rgba(var(--theme-primaria))" v-if="recurrency > 1"></span>
      </template>

        <InstallmentsTemplate
        :payrollInstallments="payrollInstallments">
          <template #extension>
            <div class="mb-4">
              <div class="alert-message">
                <span v-if="params.companyName" v-text="params.companyName"></span>
                <em v-else>Nenhuma empresa vinculada</em>
                <span v-if="params.companyCnpj">CNPJ: {{ formatterHelper.formatCnpj(params.companyCnpj) }}</span>
                <em v-else>Nenhum CNPJ vinculado</em>
              </div>
              <v-btn class="btn btn-secondary mt-4" @click="save"
                style="background-color: rgba(var(--theme-primaria)) !important">
                Pagar com débito em folha
                <i class="fal fa-angle-right"></i>
              </v-btn>
            </div>
          </template>
      </InstallmentsTemplate>
      
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<style>
.alert-message {
  background-color: #F2F3F3;
  border: 1px solid #E0E1E2;
  border-radius: 8px;
  color: #4C4D4F;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import InstallmentsTemplate from "./InstallmentsTemplate.vue";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import CheckoutService from "@/scripts/services/checkout.service.js"
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Services
import CompanyService from "@/scripts/services/company.service";
import cartStorageService from "@/scripts/services/cartStorage.service";

export default {
  emits: ["redirect"],
  components: { InstallmentsTemplate },
  props: ["params", "openPanel"],
  computed: {
    ...mapGetters([
      "checkoutValue",
      "cartLocal",
      "profile",
      "consentTermIsChecked",
      "selectedGift",
      "itemsToCheckout",
      "campaignLandingPageConfig",
      "selectedInstallment",
      "recurrency",
      "payrollInstallment"
    ]),
    paymentType: {
      get() {
        return this.$store.getters.paymentType;
      },
      set(value) {
        this.$store.dispatch('setPaymentType', value)
      }
    },
    userCompanyName() {
      return this.$store.getters.profile.companyName;
    },
    userCompanyDocument() {
      return this.$store.getters.profile.cnpj;
    }
  },
  async mounted() {
    await this.getPayrollDeductionInstallments();
  },
  watch: {
    panelOpen(value) {
      if (value === null) return
      this.$emit('update:activePaymentMethod','PayrollDeduction')
      this.$emit("set-open-panel", 'PayrollDeduction')
      this.paymentType = 'PayrollDeduction'
      this.cartLocal.paymentMethodId = PaymentMethod[this.paymentType]
      this.panelOpen = 0

      this.createInstallments({
        campaignId: this.campaignLandingPageConfig.campaignId,
        value: (this.checkoutValue),
        paymentMethodId: PaymentMethod[this.paymentType],
        installment: 0
      });
    },
    openPanel(newVal) {
      if (newVal !== "PayrollDeduction") {
        this.panelOpen = null;
      }
    },
    paymentType(value) {
      if (value !== 'PayrollDeduction') this.panelOpen = null
    }
  },
  data() {
    return {
      companyService: new CompanyService(),
      checkoutService: new CheckoutService(),
      formatterHelper: new FormatterHelper(),
      payrollInstallments: {},
      panelOpen: null,
    };
  },
  methods: {
    ...mapActions([
      "createInstallments",
      "setPayrollInstallment",
      "setInstallment",
      "clearCart"
    ]),
    save() {
      if (this.consentTermIsChecked) this.createOrder()
      else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Para prosseguir é necessário confirmar o termo de anuência!"
        );
      }
    },
    createOrder() {
      const cart = cartStorageService.getCart()

      const order = {
        userId: this.profile.userId,
        cartId: (cart && cart.id) ? cart.id : 0,
        campaignId: this.campaignLandingPageConfig.campaignId,
        campaignProductId: this.cartLocal.campaignProductId,
        paymentMethodId: this.cartLocal.paymentMethodId,
        paymentRecurrencePeriodId: this.recurrency,
        parcelamentPayRollAccount: 0,
        paymentDay: 0,
        installments: 0,
        parcelamentPayrollId: this.payrollInstallment.id,
        totalValue: this.checkoutValue,
        donationProductId: 0,
        coin: "BRL",
        cartItems: this.createItemsObject(),
        giftId: this.selectedGift ? this.selectedGift.id : null,
        giftValue: this.selectedGift ? this.selectedGift.initialValue : null
      }

      if (cart && cart.id) {
        order.creationDate = cart.creationDate
      }

      this.checkoutService.postCheckout(order).then((response) => {
        if (response) {
          this.clearCart()
          this.redirect({
            method: 'desconto-folha',
            data: response
          })
        }
      })
    },
    createItemsObject() {
      const items = [];
      for (let item of this.itemsToCheckout) {
        items.push({
          id: item.id,
          projectId: item.projectId,
          project: item.project,
          itemValue: parseFloat(item.itemValue),
          donationProduct: item.donationProduct,
          donationProductId: item.donationProductId
        })
      }
      return items
    },
    async getPayrollDeductionInstallments() {
      const cart = cartStorageService.getCart();
      this.payrollInstallments = await this.companyService.listParcelamentsPayRoll(cart.campaignId, cart.companyId);
      this.setPayrollInstallment(this.payrollInstallments[0]),
      this.setInstallment(this.payrollInstallments[0])
    },
    redirect(data) {
      this.$emit("redirect", data);
    },
  },
};
</script>
