<template>
  <v-layout>
    <Simulation
      :campaign="campaignLandingPageConfig && campaignLandingPageConfig.campaign ? campaignLandingPageConfig.campaign : null"
      :landingPageOptions="landingPageOptions" :linkToDonation="'#'" @donate="addDonationValueFromSimulation"
      @calculate="addDonationValueFromSimulation" />
    <div class="v-window-head">
      <div class="title">
        <div class="title-text">
          <h2>Valor da doação</h2>
          <div class="dot" style="background: rgba(var(--theme-primaria));"></div>
        </div>
        <div class="title-action">
          <v-btn class="btn btn-secondary" @click="toggleSimulation()"
            v-if="campaignLandingPageConfig.campaign.campaignTypeId === campaignTypes.Incentivada">
            <i class="fal fa-calculator-alt"></i>
            Faça uma simulação
          </v-btn>
        </div>
      </div>
      <div class="box" v-if="campaignDonationProduct">
        <div class="box-section">
          <div class="box-title">
            <h3>{{ campaignDonationProduct ? campaignDonationProduct.name : '' }}</h3>
            <i class="fas fa-info-circle primary" style="color: rgba(var(--theme-primaria))"></i>
          </div>
          <div class="box-subtitle">
            <p v-if="campaignDonationProduct.suggestionValue" style="color: rgba(var(--theme-primaria))">
              {{ campaignDonationProduct.finalValue !== '0' ?
                `${formatterHelper.formatMoneyWithCents(campaignDonationProduct.initialValue)} à
              ${formatterHelper.formatMoneyWithCents(campaignDonationProduct.finalValue)}` :
                `Mais de ${formatterHelper.formatMoneyWithCents(campaignDonationProduct.initialValue)}` }}
            </p>
            <v-btn class="btn btn-border" @click="openDonationProductModal()">
              Alterar faixa de valor
            </v-btn>
          </div>
          <div class="box-donation p-top">
            <label for="donation-field">Informe o valor da sua doação</label>
            <div id="donation-field" class="custom-field-text highlight lg">
              <span style="color: rgba(var(--theme-primaria))">R$</span>
              <input style="color: rgba(var(--theme-primaria))" autofocus type="text" v-model="donationValue"
                @blur="addDonationValue" @click="setCheckMinimumValueOff(false)" v-money="money"
                ref="donationValueInput" />
            </div>
            <caption>
              Valor mínimo de doação {{ campaignDonationProduct ?
                formatterHelper.formatMoneyWithCents(campaignDonationProduct.initialValue) : '' }}
            </caption>
          </div>
          <div class="box-item-resume extra" v-if="selectedGift">
            <i class="fal fa-level-up fa-rotate-90 light big"></i>
            <div class="item-name border">
              <span style="color: rgba(var(--theme-primaria))">Agradecimento adicional</span>
              <p>{{ selectedGift.name }}</p>
            </div>
            <div class="item-value">
              {{ formatterHelper.formatMoneyWithCents(selectedGift.initialValue) }}
            </div>
            <v-btn variant="text" class="item-action btn btn-border" @click="openRemoveAcknowledgementConfirmation">
              <i class="fal fa-trash light"></i>
            </v-btn>
          </div>
          <div class="value-total">
            <caption>
              Valor total da doação:
            </caption>
            <p style="color: rgba(var(--theme-primaria))">{{ formatterHelper.formatMoneyWithCents(this.checkoutValue) }}
            </p>
          </div>
        </div>
      </div>

      <div v-else class="box">
        <div class="box-section">
          <div class="box-donation p-top">
            <label for="donation-field">Informe o valor da sua doação</label>
            <div id="donation-field" class="custom-field-text highlight lg">
              <span style="color: rgba(var(--theme-primaria))">R$</span>
              <input style="color: rgba(var(--theme-primaria))" autofocus type="text" v-model="donationValue"
                @blur="addDonationValue" @click="setCheckMinimumValueOff(false)" v-money="money"
                ref="donationValueInput" />
            </div>
            <caption v-if="minimumValue">
              Valor mínimo de doação {{
                formatterHelper.formatMoneyWithCents(minimumValue) }}
            </caption>
          </div>

          <div class="value-total">
            <caption>
              Valor total da doação:
            </caption>
            <p style="color: rgba(var(--theme-primaria))">{{ formatterHelper.formatMoneyWithCents(this.checkoutValue) }}
            </p>
          </div>

        </div>
      </div>

      <h3 class="default-text">
        <b v-if="checkoutItems.length > 1">Projetos selecionados:</b>
        <b v-else>Projeto selecionado:</b>
      </h3>

      <p class="default-text" v-if="(checkoutItems.length > 1) && campaignDonationProduct">
        Para alterar o valor de doação para cada projeto, altere o valor em cada um dos campos, respeitando o valor
        total informado acima.
      </p>
      <p class="default-text" v-if="(checkoutItems.length > 1) && !campaignDonationProduct">
        Digite o valor que deseja doar para cada projeto.
      </p>
    </div>
    <div class="cart-item" v-for="(item, index) of checkoutItems" :key="'item' + item.id">
      <div class="item-image"
        :style="item.project && item.project.projectLandingPageConfig ? (item.project.projectLandingPageConfig.heroFile.path ? `--background-image: url('${item.project.projectLandingPageConfig.heroFile.path}');` : `--background-image: url('${item.project.projectLandingPageConfig.heroFile.imageContent}');`) : `--background-image: url('${item.donationProduct.file.path}');`"
        style="background-size: cover;"></div>
      <div class="cart-item-details">
        <div class="item-title">
          <div class="item-name">
            <span style="color: rgba(var(--theme-primaria)); text-align: left;">{{ item.project.institution.entity.name
              }}</span>
            <p>{{ item.project.shortName }}</p>
          </div>
          <v-btn variant="text" class="item-action btn btn-border" @click="openRemoveProjectConfirmation(item)">
            <i class="fal fa-trash light"></i>
          </v-btn>
        </div>
        <div class="item-input">
          <div class="custom-field-text text-lg text-highlight text-end">
            <span>R$</span>
            <input style="color: rgba(var(--theme-primaria))" :ref="'inputValue' + item.id" type="text"
              name="inputProjectValue" v-money="money" v-model="item.itemValue" @blur="addProjectValue(item)"
              :tabindex="index" :id="item.id" />
          </div>
          <div class="item-input-action" v-if="hasCampaignProduct()">
            <v-btn variant="text" class="item-action btn btn-highlight" @click="lock('inputValue' + item.id)">
              <i class="fal fa-lock-open light" :id="'lockIconinputValue' + item.id"></i>
            </v-btn>
          </div>
        </div>
        <label v-if="item.itemValue === '0,00'" class="d-flex justify-end msg-validation">
          Campo obrigatório
        </label>
      </div>
      <InfoProjectCard ref="infoProject" @simulation="landingPageOptions.simulationDrawer = simulationDialog">
      </InfoProjectCard>
    </div>
    <ModalCampaignDonationProducts ref="modalCampaignDonationProducts" :isModal="false"
      :title="campaignLandingPageConfig && campaignLandingPageConfig.campaign ? campaignLandingPageConfig.campaign.productCall : null"
      :campaignLandingPage="campaignLandingPageConfig" :donationProductList="campaignDonationProducts"
      @close="closeDonationProductsModal()" />
    <AucAlert ref="confirmationRemoveAcknowledgement" title="Você tem certeza que deseja excluir esse agradecimento?"
      confirmButtonText="Sim" cancelButtonText="Não" @confirm="removeGift()" />
    <AucAlert ref="confirmationRemoveProject" title="Você tem certeza que deseja excluir esse projeto?"
      confirmButtonText="Sim" cancelButtonText="Não" @confirm="remove()" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VMoney } from 'v-money'
import { Routes } from "@/scripts/models/enums/routes.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import InfoProjectCard from "@/components/InfoProjectCard.vue";
import AucAlert from "@/components/AucAlert.vue";
import ModalCampaignDonationProducts from "@/components/ModalCampaignDonationProducts.vue";
import CampaignService from "@/scripts/services/campaign.service";
import PaymentInstallmentService from "@/scripts/services/paymentInstallment.service"
import CartService from "@/scripts/services/cart.service"
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import Simulation from "@/components/Simulation.vue";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";


export default {
  directives: { money: VMoney },
  components: {
    InfoProjectCard,
    AucAlert,
    Simulation,
    ModalCampaignDonationProducts
  },
  data() {
    return {
      campaignService: new CampaignService(),
      landingPageOptions: new CampaignLandingPageOptions(),
      paymentInstallmentService: new PaymentInstallmentService(),
      campaignTypes: CampaignTypes,
      cartService: new CartService(),
      formatterHelper: new FormatterHelper(),
      paymentMethods: PaymentMethod,
      donationValue: null,
      oldDonationValue: null,
      notFormatedDonationValue: null,
      MaintanenceValueDialog: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        precision: 2,
        masked: false
      },
      simulationDialog: false,
      minimumDonationValue: null,
      product: { name: "teste" },
      campaignDonationProducts: [],
      projectToRemove: null,
      totalValue: 0,
    };
  },
  created() {
    this.checkoutItems = this.cartItems
  },
  async mounted() {
    // Recupera a última doação na campanha de referência
    this.getLastSell();
    // Recarrega o carrinho do local storage
    this.refreshCart();
    // Limpa os itens do checkout para reinicia-lo
    this.clearCheckout();
    // Verifica se tem itens no carrinho se não volta para a LP
    this.hasItemsOnCart();
    // Recarrega da API os CampaignGifts
    this.refreshCampaignGifts();
    // Recarrega da API os Produtos da Campanha
    this.refreshCampaignProducts()
    // Recarrega da API a LandingPageConfig
    await this.setLandingPageConfig();
    // Popula o valor mínimo de doação
    this.setMinimumPeriodDonationValue();
    // Recarrega da API os Produtos da Campanha
    this.getCampaignProduct();
    // Inicia o brinde selecionado como null
    this.setSelectedGift(null)
    // Busca na API o Gift Config da campanha
    this.refreshGiftsConfig(this.campaignId)
    // Armazena no vuex o ReachedMinimumValue
    this.setReachedMinimumValue(false)
    // Busca na API os termos de consentimento padrão
    this.getDefaultConsentTerms()
    // Busca na API os termos de consentimento da campanha
    this.getCampaignConsentTerms()
  },
  watch: {
    donationValue(newValue, oldValue) {
      if (newValue === oldValue) return
      this.notFormatedDonationValue = parseFloat(this.donationValue.replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.'))

      const inputIsActive = this.$refs["donationValueInput"] === document.activeElement

      if (this.donationValue === '0,00' && !inputIsActive) {
        this.setReachedMinimumValue(false)
      }
    },
    checkoutValue(newValue, oldValue) {
      if (newValue === oldValue) return
      if (this.currentAppreciation)
        this.alterCampaignDonationProduct(this.currentAppreciation.id)
    },
    selectedGift: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return
        this.makeGiftCalc()
        if (this.donationValue === '0,00') {
          this.createValues()
          this.setReachedMinimumValue(false)
        }
      },
      deep: true
    },
    campaignDonationProduct: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return
        if (newValue && this.donationValue === '0,00') {
          this.createValues()
          this.setReachedMinimumValue(false)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      "cartLocal",
      "itemsToCheckout",
      "campaignLandingPageConfig",
      "campaignDonationProduct",
      "lastSell",
      "selectedGift",
      "checkoutValue",
      "cartItems",
      "currentAppreciation",
      "campaignProducts",
      "checkMinimumValueOff",
      "campaignId",
      "showCheckoutItems",
    ]),


    checkoutItems: {
      get() {
        return this.showCheckoutItems
      },
      set(val) {
        this.setCheckoutItems(val)
      }
    },
    minimumValue() {
      return this.campaignDonationProduct ? this.campaignDonationProduct.initialValue : this.minimumDonationValue
    },
    allValue() {
      if (this.selectedGift) {
        return this.donationValue + this.selectedGift.initialValue
      } else {
        return this.donationValue
      }
    }
  },
  methods: {
    ...mapActions([
      "removeToCart",
      "clearCheckout",
      "setToCheckout",
      "refreshCart",
      "setLandingPageConfig",
      "setReachedMinimumValue",
      "getLastSell",
      "refreshCampaignGifts",
      "refreshCampaignProducts",
      "setSelectedGift",
      "setCartItems",
      "getCurrentAppreciation",
      "alterCampaignDonationProduct",
      "setCheckMinimumValueOff",
      "refreshGiftsConfig",
      "clearCheckoutValues",
      "setCheckoutItems",
      "updateCart",
      "clearCart",
      "setReloadCart",
      "getDefaultConsentTerms",
      "getCampaignConsentTerms"
    ]),
    openDonationProductModal() {
      this.$refs.modalCampaignDonationProducts.open()
    },

    closeDonationProductsModal() {
      this.donationValue = '0,00'
      this.clearCheckout()
      this.setSelectedGift(null)
    },
    closeSelectGiftModal(makeCalc = false) {
      if (makeCalc) {
        this.createValues()
      }
      this.MaintanenceValueDialog = false
    },
    goToLandingPage() {
      this.$router.push({ name: Routes.app.CampaignLandingPage });
    },

    getTotalValue() {
      this.notFormatedDonationValue = this.checkoutValue

      if (this.checkIfTheValuesAreEqual() && this.checkIfMinimumValueIsReached() && this.checkoutValue !== 0) {
        this.setReachedMinimumValue(true)
      }

    },
    addDonationValueFromSimulation(value) {

      if (value <= 0) return;

      this.donationValue = this.formatterHelper.formatMoney(value)
      this.addDonationValue()
    },
    addDonationValue() {
      const donationValue = parseFloat(this.donationValue.replaceAll('.', '').replaceAll(',', '.'))

      if (donationValue >= this.minimumValue || !this.minimumValue) {
        this.unlockAllInputs();
        this.createValues();
        return
      }

      this.setReachedMinimumValue(false)

      if (donationValue > 0){
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          `O Valor mínimo para esta doação é de ${this.formatterHelper.formatMoneyWithCents(this.minimumValue)}`
        );
      }
    },
    hasCampaignProduct() {
      return (this.$store.getters.cartLocal.campaignProductId !== null || this.$store.getters.campaignProductId !== undefined)
    },
    checkInputsValuesCondition(item) {
      const inputs = this.getAllInputs()
      let inputsValue = 0
      inputs.forEach((i) => inputsValue += parseFloat((this.$refs[i][0].value).replaceAll('.', '').replaceAll(',', '.')))

      // first: if has only one input unlocked, check all if all input values is greater than the checkout total value
      // second: if more than one input is unlocked, check if the input value added is greather than the checkout value
      // third: check if has campaignProduct and is input length is greater than 1
      return ((inputsValue) <= parseFloat(this.checkoutValue.toFixed(2))) ||
        (parseFloat((item.itemValue).replaceAll('.', '').replaceAll(',', '.')) < parseFloat(this.checkoutValue.toFixed(2)) && this.unlockedElements().length >= 2) ||
        (inputs.length <= 1 || !this.hasCampaignProduct())
    },
    addProjectValue(item) {
      if (this.checkInputsValuesCondition(item) && item.itemValue !== '0,00') {
        this.updateItem(item);
        this.createValues(item);
        return
      } else if (item.itemValue === '0,00' && this.checkoutValue !== 0) {
        this.setReachedMinimumValue(false)
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          `Adicione um valor válido ao projeto.`
        );
        return
      } else if (!this.checkInputsValuesCondition(item) && this.checkoutValue !== 0) {
        this.setReachedMinimumValue(false)
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          `O valor total das doações excede ao limite informado.`
        );
      }
    },
    updateItem(item) {
      if (item.itemValue !== '0,00') this.setToCheckout(item)
    },
    checkIfMinimumValueIsReached() {
      if ((this.checkMinimumValueOff === false) && (this.checkoutItems.length === this.itemsToCheckout.length) &&
        (this.checkoutValue < this.minimumValue) && this.checkoutValue !== 0) {
        this.setReachedMinimumValue(false)
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          `O Valor mínimo para esta doação é de ${this.formatterHelper.formatMoneyWithCents(this.minimumValue)}`
        );
        return false;
      }
      return true;
    },
    appreciationValue() {
      if (this.selectedGift) {
        return parseFloat(this.selectedGift.initialValue)
      } else {
        return 0
      }
    },
    checkIfTheValuesAreEqual() {
      if (this.campaignDonationProduct) {
        if ((this.checkoutItems.length === this.itemsToCheckout.length) && this.notFormatedDonationValue &&
          ((parseFloat(this.checkoutValue.toFixed(2)) !== (parseFloat(this.donationValue.replaceAll('.', '').replaceAll(',', '.')) + this.appreciationValue())))
        ) {
          this.setReachedMinimumValue(false)
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            `Os valores de doação não conferem!`
          );
          return false;
        }
        return true;
      }
      return true;
    },
    remove() {
      this.cartItems.forEach((item) => {
        if (item.project.id === this.projectToRemove.project.id) {
          this.removeToCart(item);
        }
      })
      this.checkoutItems = this.cartItems
      this.hasItemsOnCart();
      this.unlockAllInputs()
      if (this.getAllInputs().length >= 1) {
        this.createValues()
      }
    },
    removeGift() {
      this.setSelectedGift(null)
    },
    getAllInputs() {
      return Object.keys(this.$refs).filter(x => x.includes('inputValue'))
    },
    lock(inputRef) {
      if (this.hasCampaignProduct()) {
        const input = this.$refs[inputRef]
        if (input[0].value !== '0,00' && this.unlockedElements().length > 0) {
          input[0].disabled = !input[0].disabled

          if (input[0].disabled === true) {
            document.getElementById(`lockIcon${inputRef}`).classList.remove('fa-lock-open')
            document.getElementById(`lockIcon${inputRef}`).classList.add('fa-lock')
            document.getElementById(`lockIcon${inputRef}`).classList.add('text-red')
          } else {
            document.getElementById(`lockIcon${inputRef}`).classList.remove('fa-lock')
            document.getElementById(`lockIcon${inputRef}`).classList.remove('text-red')
            document.getElementById(`lockIcon${inputRef}`).classList.add('fa-lock-open')
          }
          this.verifyAndLockInputs()
        } else if (this.unlockedElements().length === 0) {
          this.unlockAllInputs()
        }

      }
    },
    verifyAndLockInputs() {
      const inputs = this.getAllInputs()
      const inputsUnlocked = []
      for (const input of inputs) {
        const refInput = this.$refs[input][0]
        if (refInput && !refInput.disabled) inputsUnlocked.push(input)
      }

      if (inputsUnlocked.length <= 1) {
        for (const input of inputsUnlocked) {
          this.lock(input)
        }
      }
    },
    getNumberOfInputs() {
      const inputsRefs = Object.keys(this.$refs).filter(x => x.includes('inputValue'))
      return inputsRefs.length
    },
    unlockAllInputs() {
      const inputs = document.getElementsByName('inputProjectValue')
      Array.prototype.map.call(inputs, (x) => {
        if (this.$refs[`inputValue${x.id}`] && this.$refs[`inputValue${x.id}`][0].disabled) {
          document.getElementById(`lockIconinputValue${x.id}`).classList.remove('fa-lock')
          document.getElementById(`lockIconinputValue${x.id}`).classList.remove('text-red')
          document.getElementById(`lockIconinputValue${x.id}`).classList.add('fa-lock-open')
        }
        x.disabled = false
      })
    },
    hasItemsOnCart() {
      if (this.checkoutItems.length === 0) this.$router.push({ name: Routes.app.CampaignLandingPage });
      else return
    },
    toggleSimulation() {
      this.landingPageOptions.simulationDrawer = !this.landingPageOptions
        .simulationDrawer;
    },
    updateSimulationValue(data) {
      this.simulationValue = data;
    },
    getCampaignProduct() {
      this.campaignService.listCampaignProductByCampaignId().then((response) => {
        this.campaignDonationProducts = response;
      })
    },
    createValues(item = null) {
      const allInputs = this.getInputValues()
      const unlockedElements = allInputs.filter((input) => input.status === 'unlocked')

      let value = 0

      allInputs.forEach((input) => {
        if (input.status === "unlocked") return
        value += parseFloat((input.value).replaceAll('.', '').replaceAll(',', '.'))
      })

      if (item !== null)
        value += parseFloat(item.itemValue.replaceAll('.', '').replaceAll(',', '.'))

      this.makeCalc(value, unlockedElements, item)
    },
    async makeCalc(lockedValues, unlockedElements, item) {
      if (this.minimumValue <= parseFloat(this.donationValue.replaceAll('.', '').replaceAll(',', '.'))) {
        let value = 0

        value = (parseFloat((this.donationValue).replaceAll('.', '').replaceAll(',', '.').replaceAll('R$', '')) - parseFloat(lockedValues))

        if (this.selectedGift) {
          value += parseFloat(this.selectedGift.initialValue);
        }

        let calc = 0
        if (value < 0) value = 0

        let division = item ? (this.unlockedElements().length - 1) : (this.unlockedElements().length)
        calc = await this.paymentInstallmentService.makeInstallmentsByValueAndInstallment(value, division !== 0 ? division : 1)
        this.spreadValues(calc, item)
      } else {
        if (!this.campaignDonationProduct) this.checkValuesWithoutDonationProduct();
        else {
          if (!this.checkMinimumValueOff && this.checkoutValue !== 0) {
            this.setReachedMinimumValue(false)
            this.$store.commit(
              "SET_SNACKBAR_MESSAGE",
              `O Valor mínimo para esta doação é de ${this.formatterHelper.formatMoneyWithCents(this.minimumValue)}`
            );
          } else {
            let value = (parseFloat((this.donationValue).replaceAll('.', '').replaceAll(',', '.').replaceAll('R$', '')) - parseFloat(lockedValues))
            if (this.selectedGift) {
              value += parseFloat(this.selectedGift.initialValue);
            }

            let calc = 0
            if (value < 0) value = 0
            if (unlockedElements.length > 0 && value > 0) {
              calc = await this.paymentInstallmentService.makeInstallmentsByValueAndInstallment(value, unlockedElements.length)
            }
            this.spreadValues(calc)
          }

        }
      }
    },
    checkValuesWithoutDonationProduct() {
      this.getTotalValue()
    },
    getInputValues() {
      const values = []
      this.checkoutItems.forEach((item) => {
        const input = this.$refs['inputValue' + item.id][0]
        if (input.disabled && input.value) values.push({
          ref: 'inputValue' + item.id,
          value: input.value,
          status: 'locked'
        })
        else {
          values.push({
            ref: 'inputValue' + item.id,
            value: input.value,
            status: 'unlocked'
          })
        }
      })
      return values;
    },
    unlockedElements() {
      const allInputs = this.getInputValues()
      return allInputs.filter((x) => x.status === 'unlocked')
    },
    async makeGiftCalc() {
      let giftValue = 0;
      if (this.selectedGift) giftValue = parseFloat(this.selectedGift.initialValue);
      else giftValue = Math.abs(parseFloat(this.donationValue.replaceAll('.', '').replaceAll(',', '.')) - this.checkoutValue)
      console.log('makeGiftCalc', giftValue)
      const calc = await this.paymentInstallmentService.makeInstallmentsByValueAndInstallment(giftValue, this.cartItems.length)
      if (this.selectedGift) {
        this.spreadGiftValues(calc)
      } else {
        this.removeGiftFromInputs(calc)
      }
    },
    spreadGiftValues(calc) {
      let counter = 0
      this.checkoutItems.forEach((checkoutItem) => {
        const input = this.$refs['inputValue' + checkoutItem.id][0]
        const value = calc[counter].value + parseFloat((checkoutItem.itemValue).replaceAll('.', '').replaceAll(',', '.'))
        input.value = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value)
        checkoutItem.itemValue = input.value
        this.setToCheckout(checkoutItem)
        counter++
      })
    },
    removeGiftFromInputs(calc) {
      let counter = 0
      this.checkoutItems.forEach((checkoutItem) => {
        const input = this.$refs['inputValue' + checkoutItem.id][0]
        const value = parseFloat((checkoutItem.itemValue).replaceAll('.', '').replaceAll(',', '.')) - calc[counter].value
        input.value = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value)
        checkoutItem.itemValue = input.value
        this.setToCheckout(checkoutItem)
        counter++
      })
    },
    spreadValues(values, item) {
      if (values === 0) return
      let counter = 0

      if (this.donationValue === '0,00') {
        this.checkoutItems.forEach((checkoutItem) => {
          const input = this.$refs['inputValue' + checkoutItem.id][0]

          input.value = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0)
          checkoutItem.itemValue = input.value
          this.setToCheckout(checkoutItem)
        })
      }

      this.checkoutItems.forEach((checkoutItem) => {
        const input = this.$refs['inputValue' + checkoutItem.id][0]
        if ((!item && !input.disabled) || (item && !input.disabled && item.id !== checkoutItem.id)) {
          input.value = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(values[counter].value)
          checkoutItem.itemValue = input.value
          this.setToCheckout(checkoutItem)
          counter++
        }
      })
      this.getTotalValue()
      if (item) this.lock('inputValue' + item.id)
      this.verifyAndLockInputs();
    },


    setMinimumPeriodDonationValue() {
      let minimumDonationValue = this.campaignLandingPageConfig.campaign.minimumDonationValue;

      if (!this.cartLocal.campaign) this.cartLocal.campaign = this.campaignLandingPageConfig.campaign;

      this.cartLocal.campaign.minimumDonationValue = minimumDonationValue;

      const isCreditCart = this.cartLocal.paymentMethodId == this.paymentMethods.CreditCard
      const recurrentPeriodInformed = this.cartLocal.donationRecurrencePeriodId

      if (!isCreditCart || !recurrentPeriodInformed) {
        this.minimumDonationValue = minimumDonationValue;
        return
      }

      const recurrencePeriod = parseInt(this.cartLocal.donationRecurrencePeriodId);
      const campaignDonationRecurrencePeriodList = this.campaignLandingPageConfig.campaign.campaignDonationRecurrencePeriodList.find(
        (recurrentPeriod) => recurrentPeriod.donationRecurrencePeriodId == recurrencePeriod
      );

      minimumDonationValue =
        campaignDonationRecurrencePeriodList.minimumDonationValue > 0
          ? campaignDonationRecurrencePeriodList.minimumDonationValue
          : this.campaignLandingPageConfig.campaign.minimumDonationValue;

      this.minimumDonationValue = minimumDonationValue;
    },
    openRemoveAcknowledgementConfirmation() {
      this.setCheckMinimumValueOff(true)
      this.$refs.confirmationRemoveAcknowledgement.open();
    },
    openRemoveProjectConfirmation(item) {
      this.projectToRemove = item
      this.$refs.confirmationRemoveProject.open();
    },
  },
};
</script>


<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>